import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress, Collapse } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { getWorkingNodes } from 'src/config/nodes';

const getNodeName = async (url: string) => {
  try {
    const response = await axios.get(`https://${url}/name`);
    const nodeName: string = response.data;
    return nodeName;
  } catch (error) {
    console.log('getNodeName Error: ', error);
    throw error;
  }
};

const getNodeRegion = async (url: string) => {
  try {
    const response = await axios.post('https://backend.tigristrade.info/getRegion', { url: url });
    const region = response.data as string;
    return region;
  } catch (error) {
    console.log('getNodeRegion Error: ', error);
    throw error;
  }
};

const getLatency = async (url: string) => {
  try {
    const response = await axios.get(`https://${url}/health?timestamp=${Date.now()}`);
    const ping = Date.now() - JSON.parse(response.data.timestamp);
    return ping;
  } catch (error) {
    console.log('getLatency Error: ', error);
    return 0;
  }
};

const getNodeStatus = async (url: string) => {
  try {
    const response = await axios.get(`https://${url}/health`);
    const status = response.data.message;
    return status;
  } catch (error) {
    console.log('Fetching node status error: ', error);
    throw error;
  }
};

const getNodeGasData = async (url: string, chain: number) => {
  try {
    const response = await axios.get(`https://${url}/gas`);
    const res = response.data;
    const node = res[chain];
    let sum = 0;

    for (const key in node) {
      const value = parseFloat(node[key]);
      if (!isNaN(value)) {
        sum += value;
      }
    }

    const arr = Object.entries(node).map(([address, balance]) => ({ address, balance: parseFloat(String(balance)) }));
    return { gasData: arr, balance: sum };
  } catch (error) {
    console.log('Fetching node data error: ', error);
    const temp = [{ address: '', balance: 0 }];
    return { gasData: temp, balance: 0 };
  }
};

interface SubData {
  address: string;
  balance: number;
}

interface Data {
  node: string;
  url: string;
  region: string;
  status: string;
  latency: number;
  balance: number;
  subData: SubData[];
}

function createData(
  node: string,
  url: string,
  region: string,
  status: string,
  latency: number,
  balance: number,
  subData: SubData[]
): Data {
  return {
    node,
    url,
    region,
    status,
    latency,
    balance,
    subData
  };
}

const getRowsData = async (chain: number, workingNodes: string[]) => {
  try {
    const rows: Data[] = [];

    // Create an array of promises for each async function call
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const promisesArray = workingNodes.map((node) => {
      return Promise.all([
        getNodeName(node),
        node,
        getNodeRegion(node),
        getNodeStatus(node),
        getLatency(node),
        getNodeGasData(node, chain)
      ]);
    });

    // Execute all the promises at once using Promise.all()
    const results = await Promise.all(promisesArray);

    // Iterate through the results and push data to rows array
    results.forEach(([name, node, region, status, latency, data]) => {
      console.log({ name, region, status, latency, data });
      const gasData = data?.gasData;
      rows.push(createData(name, node, region, status, latency, Number(data?.balance), gasData));
    });

    return rows;
  } catch (err) {
    console.log('getRows failed: ', err);
  }
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: string | number | SubData[] }, b: { [key in Key]: string | number | SubData[] }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T extends Data>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'node',
    numeric: false,
    disablePadding: true,
    label: 'Node'
  },
  {
    id: 'url',
    numeric: false,
    disablePadding: true,
    label: 'URL'
  },
  {
    id: 'region',
    numeric: true,
    disablePadding: false,
    label: 'Region'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'latency',
    numeric: true,
    disablePadding: false,
    label: 'Latency'
  },
  {
    id: 'balance',
    numeric: true,
    disablePadding: false,
    label: 'Balanace'
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} padding={'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function NodeDataTable(props: { chain: number }) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('latency');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [polygonRows, setPolygonRows] = React.useState<Data[]>([]);
  const [arbitrumRows, setArbitrumRows] = React.useState<Data[]>([]);
  const { chain } = props;
  const rows = chain === 42161 ? arbitrumRows : polygonRows;
  const [isLoading, setIsLoading] = React.useState(true);

  const explorer = chain === 42161 ? 'https://arbiscan.io/' : 'https://polygonscan.com/';

  React.useEffect(() => {
    const getRow = async () => {
      const workingNodes = await getWorkingNodes();
      console.log({ workingNodes });
      const [polygonRows, arbitrumRows] = await Promise.all([
        getRowsData(137, workingNodes),
        getRowsData(42161, workingNodes)
      ]);
      if (polygonRows && arbitrumRows) {
        setPolygonRows(polygonRows);
        setArbitrumRows(arbitrumRows);
        setIsLoading(false);
      }
    };
    getRow();
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, rows]
  );

  function Row(props: { row: Data; idx: number }) {
    const { row, idx } = props;
    const [isOpen, setOpen] = React.useState(false);
    // const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${idx}`;
    return (
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.node}
          sx={{ cursor: 'pointer' }}
          onClick={() => setOpen(!isOpen)}
        >
          <TableCell component="th" id={labelId} scope="row">
            {row.node}
          </TableCell>
          <TableCell>
            <a
              href={`https://${row.url}/health`}
              target="_blank"
              rel="noreferrer noopenner"
              style={{ textDecoration: 'none', color: '#FFF' }}
            >
              {row.url}
            </a>
          </TableCell>
          <TableCell>{row.region}</TableCell>
          <TableCell>{row.status}</TableCell>
          <TableCell>{row.latency}</TableCell>
          <TableCell>{row.balance}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ borderBottom: 'none' }}>
                        Address
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: 'none' }}>
                        Balance
                      </TableCell>
                      <TableCell align="left" style={{ borderBottom: 'none' }}>
                        Tx Link
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.subData.map((sub: SubData) => (
                      <TableRow key={sub.address}>
                        <TableCell align="left" style={{ borderBottom: 'none' }}>
                          {sub.address}
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: 'none' }}>
                          {sub.balance}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ '&:hover': { color: '#3772FF' }, cursor: 'pointer', borderBottom: 'none' }}
                          onClick={() => window.open(`${explorer}address/${sub.address}`)}
                        >
                          {explorer}address/{sub.address}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      {isLoading ? (
        <LoaderContainer>
          Fetching Data...
          <CircularProgress size={120} />
        </LoaderContainer>
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row: Data, index: number) => {
                  return <Row key={index} idx={index} row={row} />;
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Box>
  );
}

const LoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '60px',
  fontSize: '24px',
  color: '#FFF',
  paddingTop: '80px'
}));
