import axios from 'axios';

const nodes = [
  'node1.tigristrade.info',
  'node2.tigristrade.info',
  'arb.do.gkx.dev',
  '173.212.215.254.sslip.io',
  '158.220.88.60.sslip.io',
  '31.220.96.192.sslip.io',
  '66.94.114.106.sslip.io',
  '144.126.143.114.sslip.io',
  '185.227.135.200.sslip.io',
  '109.123.230.231.sslip.io',
  '154.26.154.146.sslip.io',
  '140.82.24.75.sslip.io',
  '65.20.85.196.sslip.io',
  '216.238.98.37.sslip.io',
  '65.20.102.234.sslip.io',
  '149.28.208.174.sslip.io',
  '64.176.172.58.sslip.io'
];

const getWorkingEndpoints = async (endpoints: string[]) => {
  const promises = endpoints.map(async (endpoint) => {
    try {
      const response = await axios.get(`https://${endpoint}/name`);
      if (response.data) {
        return endpoint;
      } else {
        return '';
      }
    } catch (error) {
      console.log('getting Work points error: ', error);
      return '';
      // Ignore errors and continue to the next endpoint
    }
  });

  const results = await Promise.all(promises);
  const workingEndpoints = results.filter((endpoint) => endpoint !== '');

  console.log({ workingEndpoints });
  return workingEndpoints;
};

export const getWorkingNodes = async (): Promise<string[]> => {
  try {
    const result = await getWorkingEndpoints(nodes);
    return result;
  } catch (error) {
    console.log('GetWorkingNodes Error', error);
    return [];
  }
};
