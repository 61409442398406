import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { Container } from 'src/components/Container';
import CustomizedMenus from 'src/components/Dropdown';
import NodeDataTable from 'src/components/Table/NodeData';

export const Home = () => {
  const [chain, setChain] = useState(42161);
  return (
    <HomeContainer>
      <Container>
        <NodeDataTableHeader>
          <NodeDataTableText>Node Data</NodeDataTableText>
          <CustomizedMenus chain={chain} setChain={setChain} />
        </NodeDataTableHeader>
        <NodeDataTable chain={chain} />
      </Container>
    </HomeContainer>
  );
};

const HomeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingTop: '40px'
}));

const NodeDataTableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const NodeDataTableText = styled(Box)(({ theme }) => ({
  fontSize: '42px',
  color: '#fff',
  fontWeight: '500'
}));
